import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  Theme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

interface DuplicateVendsAccordionProps {
  duplicates: string[];
  expanded: boolean;
  onAccordionChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const DuplicateVendsAccordion: React.FC<DuplicateVendsAccordionProps> = ({
  duplicates,
  expanded,
  onAccordionChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyDuplicate = (duplicate: string) => {
    navigator.clipboard.writeText(duplicate);
    enqueueSnackbar(t("pages-vends-duplicate-copied"), { variant: "success" });
  };

  return (
    <Accordion
      sx={(theme: Theme) => ({
        borderRadius: theme.shape.borderRadius,
        boxShadow: "none",
        border: `1px solid ${theme.palette.primary.lighter}`,
        "&:before": {
          display: "none",
        },
      })}
      expanded={expanded}
      onChange={onAccordionChange}
    >
      <AccordionSummary
        sx={(theme: Theme) => ({
          flexDirection: "row",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(180deg)",
          },
        })}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>
          {t("pages-vends-duplicates-detected")} ({duplicates?.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme: Theme) => ({
          borderTop: `1px solid ${theme.palette.primary.lighter}`,
          maxHeight: 400,
          overflow: "auto",
          margin: 0,
          padding: 1,
        })}
      >
        <List
          sx={{
            width: "100%",
            padding: 0,
          }}
        >
          {duplicates.map((duplicate, index) => (
            <ListItem
              sx={(theme: Theme) => ({
                borderBottom: `1px solid ${theme.palette.primary.lighter}`,
                "&:last-child": {
                  borderBottom: "none",
                },
                padding: theme.spacing(1, 0),
              })}
              key={index}
            >
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item xs={1.5}>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    color="text.secondary"
                  >
                    {index + 1}.
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" noWrap>
                    <i>{duplicate}</i>
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Tooltip title={t("pages-vends-copy-duplicate")}>
                    <IconButton
                      edge="end"
                      aria-label="copy"
                      onClick={() => handleCopyDuplicate(duplicate)}
                      size="small"
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default DuplicateVendsAccordion;
