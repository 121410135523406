import { TableFooter, TablePagination, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ImportedVendsResponse } from "../../api/vends";

interface ImportedVendsTableFooterProps {
  data: ImportedVendsResponse[];
  rowsPerPage: number;
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImportedVendsTableFooter: React.FC<ImportedVendsTableFooterProps> = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const { t } = useTranslation();
  return (
    <TableFooter>
      <TableRow
        sx={{
          "& td, & th": {
            borderColor: "primary.lighter",
          },
        }}
      >
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            50,
            { value: -1, label: t("global-table-rows-all") },
          ]}
          colSpan={6}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": t("global-table-rows-per-page") },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("global-table-rows-per-page")}
        />
      </TableRow>
    </TableFooter>
  );
};

export default ImportedVendsTableFooter;
