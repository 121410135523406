import { Box, Container } from "@mui/material";
import CustomCard from "../../../components/universal-components/CustomCard";
import { HighlightOff } from "@mui/icons-material";
import VendsUpload from "../../../components/vends/VendsUpload";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONFIG } from "../../../routes/routesConfig";

const VendsUploadPage = () => {
  const navigate = useNavigate();
  const handleVendsRedirectClick = () => {
    navigate(ROUTES_CONFIG.APP.routes.VENDS.path);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "primary.lighter",
        boxSizing: "border-box",
        py: 2,
      }}
    >
      <Container>
        <CustomCard
          cardId={"vends-upload-card"}
          cardTitle={"pages-vends-upload-title"}
          cardHasButton={true}
          cardButtonPrimary={false}
          cardButtonTitle={"pages-vends-upload-close"}
          cardButtonIcon={<HighlightOff />}
          cardButtonFunc={handleVendsRedirectClick}
          cardContent={<VendsUpload />}
        />
      </Container>
    </Box>
  );
};

export default VendsUploadPage;
