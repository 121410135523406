import { Logout, Person, Settings } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ROUTES_CONFIG } from "../../routes/routesConfig";
import { MenuItem } from "./MenuItem";

interface AccountMenuProps {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleToggle: () => void;
}

export default function AccountMenu(props: AccountMenuProps) {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenAccountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      enqueueSnackbar(t("global-logout-success"), {
        variant: "success",
        key: "success-snack-logout",
      });
      await logout();
      navigate(ROUTES_CONFIG.APP.routes.LOGIN.path);
    } catch (error) {
      console.log(error, "error logging out");
      enqueueSnackbar("Error", {
        variant: "error",
        key: t("global-logout-error"),
      });
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("components-accountmenu-tooltip")}>
          <IconButton
            onClick={handleOpenAccountMenuClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 42,
                height: 42,
                color: "primary.main",
                bgcolor: "primary.lighter",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            px: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            mt: 2.75,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          title={t("global-profile")}
          route={ROUTES_CONFIG.APP.routes.PROFILE.path}
          open={props.open}
        >
          <Person
            sx={{
              height: 25,
              width: 25,
              color: "primary.main",
              bgcolor: "transparent",
            }}
          />
        </MenuItem>
        <MenuItem
          title={t("components-navigation-settings") as string}
          route={ROUTES_CONFIG.APP.routes.SETTINGS.path}
          open={props.open}
        >
          <Settings sx={{ height: 22, width: 25, color: "primary.main" }} />
        </MenuItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleLogout}
            sx={{
              borderRadius: 2,
              justifyContent: props.open ? "initial" : "center",
            }}
          >
            <ListItemIcon>
              <Logout sx={{ height: 22, width: 30, color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText
              primary={t("global-logout")}
              sx={{ color: "primary.main" }}
            />
          </ListItemButton>
        </ListItem>
      </Menu>
    </React.Fragment>
  );
}
