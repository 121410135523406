import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { DefaultInAppLayout } from "./layout/DefaultInAppLayout";
import { DefaultPublicLayout } from "./layout/DefaultPublicLayout";
import { getRoutesAsList } from "./routes/utils/routesUtil";
import { ROUTES_CONFIG } from "./routes/routesConfig";
import { useLocales } from "./hooks/useLocales";
import theme from "./style/theme";
import { RoutePath } from "./routes/models/RoutePath";
import { AuthProvider } from "./hooks/useAuth";

function App() {
  const { currentLanguage } = useLocales();

  const queryClient = new QueryClient();

  function renderPublicApp(routeObject: RoutePath) {
    const PageComponent = routeObject.component;
    return (
      <DefaultPublicLayout>
        <PageComponent />
      </DefaultPublicLayout>
    );
  }

  function renderSecureInApp(routeObject: RoutePath) {
    const PageComponent = routeObject.component;
    return (
      <DefaultInAppLayout>
        <PageComponent />
      </DefaultInAppLayout>
    );
  }

  function renderApp(routeObject: RoutePath) {
    const PageComponent = routeObject.component;
    return (
      <Route
        key={`route-${routeObject.path}`}
        path={routeObject.path}
        element={
          routeObject.isSecure
            ? renderSecureInApp(routeObject)
            : renderPublicApp(routeObject)
        }
      />
    );
  }

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={currentLanguage.locale}
        >
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              {/*<DefaultLayout>*/}
              <BrowserRouter>
                {/*<Suspense fallback={<Loader />}>*/}
                <Routes>
                  {getRoutesAsList(ROUTES_CONFIG)
                    .filter((routeObject) => routeObject.component)
                    .map((routeObject) => renderApp(routeObject))}
                </Routes>
                {/*</Suspense>*/}
              </BrowserRouter>
              {/*</DefaultLayout>*/}
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
