import { Box, Container } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CustomCard from "../../../components/universal-components/CustomCard";
import ImportedVendsList from "../../../components/vends/ImportedVendsList";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONFIG } from "../../../routes/routesConfig";

export default function VendsPage() {
  const navigate = useNavigate();
  const handleVendsRedirectClick = () => {
    navigate(ROUTES_CONFIG.APP.routes.VENDS.routes.CSV_UPLOAD.path);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "primary.lighter",
      }}
    >
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "primary.lighter",
        }}
        mt={2}
        mb={2}
      >
        <Container>
          <CustomCard
            cardId={"vends-card"}
            cardTitle={"pages-vends-title"}
            cardHasButton={true}
            cardButtonTitle={"pages-vends-upload-new"}
            cardButtonIcon={<AddCircleOutlineOutlinedIcon />}
            cardButtonPrimary={true}
            cardButtonFunc={handleVendsRedirectClick}
            cardContent={<ImportedVendsList />}
          />
        </Container>
      </Box>
    </Box>
  );
}
