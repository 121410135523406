// const letters = 'QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm     ';
const letters = "###### ";

const LENGTH = letters.length;

function transformStringToGibberish(length: number) {
  let sentence = "";
  for (let i = 0; i < length; i++) {
    sentence += letters[Math.floor(Math.random() * LENGTH)];
  }
  return sentence.replace(/\s\s/gi, " ");
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StringObj extends Record<string, StringObj | string> {}

export function transformObjectToGibberishObject(
  obj: StringObj,
  lengthInPercent: number,
) {
  const res = {} as StringObj;
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "string") {
      res[key] = transformStringToGibberish(value.length * lengthInPercent);
    } else {
      res[key] = transformObjectToGibberishObject(value, lengthInPercent);
    }
  }
  return res;
}
