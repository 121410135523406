import { useState } from "react";
import { AppBar, Grid, IconButton, Toolbar } from "@mui/material";
import AccountMenu from "./AccountMenu";
import MenuIcon from "@mui/icons-material/Menu";

interface TopNavbarProps {
  toggleOpenDrawer: Function;
}

export function TopNavbar(props: TopNavbarProps) {
  const handleToggleNavbarDrawer = () => {
    props.toggleOpenDrawer();
  };

  const [open, setOpen] = useState<boolean>(true);

  const handleOpenAccountMenu = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };
  return (
    <AppBar
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      position="fixed"
    >
      <Toolbar>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <IconButton
              size="large"
              edge="start"
              aria-label="open drawer"
              color="inherit"
              onClick={handleToggleNavbarDrawer}
              sx={{
                mr: 2,
                color: "white",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AccountMenu
              open={open}
              handleClose={handleCloseDrawer}
              handleOpen={handleOpenAccountMenu}
              handleToggle={handleToggleNavbarDrawer}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
