interface Environment {
  BACKEND_API_BASE_URL: string;
}

interface EnvironmentConfig {
  local: Environment;
  dev: Environment;
  prd: Environment;
}

export const ENV_CONFIG: EnvironmentConfig = {
  local: {
    BACKEND_API_BASE_URL: "https://vending.api.cloud.biobringer.dev/admin",
  },
  dev: {
    BACKEND_API_BASE_URL: "https://vending.api.cloud.biobringer.dev/admin",
  },
  prd: {
    BACKEND_API_BASE_URL: "https://vending.api.biobringer.cloud/admin",
  },
};

function resolveEnvironment(env: string | undefined): Environment {
  switch (env) {
    case "dev":
      return ENV_CONFIG.dev;
    case "prd":
      return ENV_CONFIG.prd;
    case "local":
    default:
      return ENV_CONFIG.local;
  }
}

export function getCurrentEnvironment(): Environment {
  const currentEnvironment = process.env.REACT_APP_STAGE;
  console.log("currentEnvironment", currentEnvironment);
  return resolveEnvironment(currentEnvironment);
}
