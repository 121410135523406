import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { TopNavbar } from "../components/navigation/TopNavbar";
import { LeftDrawer } from "../components/navigation/LeftDrawer";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { useState } from "react";
import { DRAWER_WIDTH } from "../style/theme";

interface DefaultPublicLayoutProps {
  children: React.ReactNode;
}

const AppBarOffset = styled(Box)(({ theme }) => theme.mixins.toolbar);

export function DefaultPublicLayout(props: DefaultPublicLayoutProps) {
  const { smDown } = useBreakpoint();
  const [open, setOpen] = useState<boolean>(true);

  return (
    <>
      {/*<TopNavbar/>*/}
      {/*<AppBarOffset/>*/}
      <Box component={"main"}>{props.children}</Box>
    </>
  );
}
