import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";

//
// brownVeryLight: '#F2EAE0',
//     brownLight: '#DFC0A5',
//     brownMedium: '#97470F',
//     brownDark: '#391800',
//
//     greenLight: '#C1CB86',
//     greenMedium: '#8BAF1A',
//     greenDark: '#546E0F',
//
//     yellowLight: "#f9b036",
//
//     white: '#ffffff',
//     gray: '#9D9D9D',

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

// SETUP COLORS
const PRIMARY = "#97470F";
const PRIMARY_LIGHTER = "#F2EAE0";
const PRIMARY_LIGHT = "#DFC0A5";
const PRIMARY_DARK = "#391800";

const SECONDARY = "#546E0F";
const SECONDARY_LIGHT = "#C1CB86";
const SECONDARY_DARK = "#546E0F";

const INFO = "#8ACCDA";

const SUCCESS = "#8BAF1A";

const WARNING = "#F9B036";

const ERROR = "#E87F39";
//  const ERROR = '#d69aa7';

export const DRAWER_WIDTH = 240;

let theme = createTheme({
  shape: {
    borderRadius: 3,
  },
  palette: {
    primary: {
      main: PRIMARY,
      light: PRIMARY_LIGHT,
      lighter: PRIMARY_LIGHTER,
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY,
      light: SECONDARY_LIGHT,
      dark: SECONDARY_DARK,
    },
    info: {
      main: INFO,
    },
    success: {
      main: SUCCESS,
    },
    warning: {
      main: WARNING,
    },
    error: {
      main: ERROR,
    },
    text: {
      primary: PRIMARY_DARK,
    },
    action: {},
    // background: {
    //     default: PRIMARY_LIGHTER,
    //     paper: PRIMARY_LIGHTER,
    // }
  },
  typography: {
    fontFamily: ['"Poppins"', "sans-serif"].join(","),
    // fontSize: 12,
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: PRIMARY_DARK,
        underline: "always",
        // fontWeight: 500
      },
    },
  },
});

theme = responsiveFontSizes(theme);

//             main: optimizedThemeColor,
//             darker: darken(optimizedThemeColor, 0.4),
//             dark: darken(optimizedThemeColor, 0.2),
//             lighter: lighten(optimizedThemeColor, 0.88),
//             light: lighten(optimizedThemeColor, 0.75),

export default theme;
