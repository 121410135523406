import { useTranslation } from "react-i18next";
import { ImportedVendsResponse } from "../api/vends";

// USER MANAGEMENT ROLES DISPLAY HELPERS:
export const getRoleColor = (role: string) => {
  switch (role) {
    case "ADMIN":
      return "#8ACCDA";
    case "USER_MNGR":
      return "#8BAF1A";
    default:
      return "#97470F";
  }
};
export const createGetRoleDisplayName =
  (t: TranslationFunction) => (role: string) => {
    switch (role) {
      case "ADMIN":
        return t("pages-settings-admin");
      case "USER_MNGR":
        return t("pages-settings-user-mngr");
      default:
        return role;
    }
  };

//VALIDATION TRANSLATION TYPE (GENERIC)
export type TranslationFunction = (key: string, options?: object) => string;

//Format Date Function
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleString("de", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

//Format Value
export const formatValue = (value: number): string => {
  return value.toFixed(2);
};

//VENDS HEADCELLS:
export type SortableKeys = keyof ImportedVendsResponse;

interface HeadCell {
  key: SortableKeys;
  label: string;
  align: string;
}

export const useTranslatedHeadCells = (): HeadCell[] => {
  const { t } = useTranslation();

  const headCells: HeadCell[] = [
    { key: "timestamp", label: t("global-date"), align: "left" },
    {
      key: "product_description",
      label: t("global-product_description"),
      align: "left",
    },
    { key: "quantity", label: t("global-qty"), align: "right" },
    { key: "value", label: t("global-amount"), align: "right" },
    { key: "payment", label: t("global-payment_method"), align: "left" },
    { key: "machine", label: t("pages-vends-machine"), align: "left" },
  ];
  return headCells;
};
