import { Box } from "@mui/system";
import React from "react";
import theme from "../../style/theme";

const MessageBox = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding={4}
    border={1}
    borderColor="primary.lighter"
    borderRadius={theme.shape.borderRadius}
  >
    {children}
  </Box>
);

export default MessageBox;
