import { useTranslation } from "react-i18next";
import DeFlag from "../assets/langs/ic_flag_de.svg";
import EnFlag from "../assets/langs/ic_flag_en.svg";
import GibberishFlag from "../assets/langs/gibberish.png";
import { enUS, de } from "date-fns/locale";
// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: EnFlag,
    locale: enUS,
  },
  {
    value: "de",
    label: "German",
    icon: DeFlag,
    locale: de,
  },
];

if (process.env["NODE_ENV"] === "development") {
  LANGS.push({
    value: "gib",
    label: "Gibberish",
    icon: GibberishFlag,
    locale: de,
  });
}

export function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const currentLanguage =
    LANGS.find((_lang) => _lang.value === i18n.language) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    changeLanguage: handleChangeLanguage,
    translate,
    currentLanguage: currentLanguage,
    languages: LANGS,
  };
}
