import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocales } from "../../hooks/useLocales";

interface RefillSuccessPageProps {}

export default function RefillSuccessPage(props: RefillSuccessPageProps) {
  const { t } = useTranslation();
  const { currentLanguage } = useLocales();

  return <>ERFOLGREICH!</>;
}
