import commonDE from "./de/translation.json";
import commonEN from "./en/translation.json";
import i18n, {
  i18n as i18nType,
  Module,
  Newable,
  Resource,
  ThirdPartyModule,
} from "i18next";
import { transformObjectToGibberishObject } from "./gibberish";

const resources = {
  en: { common: commonEN },
  de: { common: commonDE },
} as Resource;

if (process.env["NODE_ENV"] === "development") {
  resources["gib"] = {
    common: transformObjectToGibberishObject(commonEN, 1.4),
  };
}

export function init<T extends Module>(
  ...params: (T | Newable<T> | ThirdPartyModule | Newable<ThirdPartyModule>)[]
): i18nType {
  const i18nInstance = i18n;
  for (const plugin of params) {
    i18nInstance.use(plugin);
  }

  i18nInstance.init({
    resources,
    ns: ["common"],
    defaultNS: "common",
    fallbackLng: "de",
    interpolation: { escapeValue: false },
  });
  return i18nInstance;
}
