import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import React, { useMemo, useState } from "react";
import { ImportedVendsResponse } from "../../api/vends";
import ImportedVendsRowElement from "./ImportedVendsRowElement";
import ImportedVendsTableFooter from "./ImportedVendsTableFooter";
import ImportedVendsTableHeader from "./ImportedVendsTableHeader";
import { SortableKeys } from "../../utils/helpers";

interface SortableImportedVendsTableProps {
  data: ImportedVendsResponse[];
  sortableFields?: SortableKeys[];
}

const ImportedVendsTable = ({
  data,
  sortableFields = [
    "timestamp",
    "product_description",
    //"quantity",
    //"value",
    "payment",
    "machine",
  ],
}: SortableImportedVendsTableProps): JSX.Element => {
  const [orderBy, setOrderBy] = useState<SortableKeys>("timestamp");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: SortableKeys) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => {
      if (!sortableFields.includes(orderBy)) return 0;
      if (b[orderBy] < a[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    });
  }, [data, orderBy, order, sortableFields]);

  const paginatedData = useMemo(
    () =>
      rowsPerPage > 0
        ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedData,
    [sortedData, page, rowsPerPage],
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: "1px solid",
        borderBottom: "none",
        borderColor: "primary.lighter",
        borderRadius: 1,
      }}
    >
      <Table aria-label="imported-vends-table" stickyHeader>
        <ImportedVendsTableHeader
          orderBy={orderBy}
          order={order}
          sortableFields={sortableFields}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {paginatedData.map((row) => (
            <ImportedVendsRowElement key={row.id} {...row} />
          ))}
        </TableBody>
        <ImportedVendsTableFooter
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
};

export default ImportedVendsTable;
