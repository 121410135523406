import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../style/theme";

interface CustomCardProps {
  cardId: string;
  cardTitle: string;
  cardHasButton?: boolean;
  cardButtonPrimary?: boolean;
  cardButtonFunc?: () => void;
  cardButtonTitle?: string;
  cardButtonIcon?: React.ReactNode;
  cardContent?: React.ReactNode;
}

const customCardStyles = {
  mb: 2,
  borderRadius: theme.shape.borderRadius,
  border: "none",
  boxShadow: "0px 2px 8px rgba(0,0,0,0.12)",
};

const CustomCard: React.FC<CustomCardProps> = ({
  cardId,
  cardTitle,
  cardHasButton = true,
  cardButtonPrimary = true,
  cardButtonFunc,
  cardButtonTitle,
  cardButtonIcon,
  cardContent,
}) => {
  const { t } = useTranslation();

  return (
    <Card key={cardId} variant="outlined" sx={customCardStyles}>
      <Grid container justifyContent={"space-between"} sx={{ pr: 2 }}>
        <Grid item>
          <CardHeader
            sx={{ color: "primary.main", fontWeight: "bold" }}
            title={t(cardTitle)}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cardHasButton && cardButtonTitle && (
            <Button
              onClick={cardButtonFunc}
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                borderRadius: 1.5,
                fontSize: 14,
              }}
              size="small"
              variant={cardButtonPrimary ? "contained" : "outlined"}
              startIcon={cardButtonIcon}
            >
              {t(cardButtonTitle)}
            </Button>
          )}
        </Grid>
      </Grid>
      {cardContent && <CardContent>{cardContent}</CardContent>}
    </Card>
  );
};

export default CustomCard;
